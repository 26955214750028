import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Form, Dropdown } from 'react-bootstrap';

import { ReactComponent as ErrorIcon } from 'assets/images/error-outline.svg';
import 'components/FormInput/styles.scss';
import './styles.scss';

const TypeAheadInput = React.forwardRef(
  (
    {
      options,
      onChange,
      onBlur,
      error,
      label,
      placeholder,
      inputClassName,
      labelClassName,
      defaultValue,
      ...rest
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState(defaultValue);
    const [openMenu, setOpenMenu] = useState(false);
    const [optionsList, setOptionsList] = useState([]);
    const [selectedOption, setSelectedOption] = useState(defaultValue);

    useEffect(() => {
      setInputValue(defaultValue);
    }, [defaultValue]);

    useEffect(() => {
      if (options?.length && inputValue !== defaultValue) {
        const filteredOptions =
          options?.filter(
            item =>
              inputValue &&
              selectedOption?.toLowerCase() !== inputValue?.toLowerCase() &&
              item.label?.toLowerCase()?.startsWith(inputValue?.toLowerCase())
          ) || [];
        setOptionsList(filteredOptions);
        setOpenMenu(filteredOptions?.length ? true : false);
      }
    }, [inputValue, options, selectedOption, defaultValue]);

    const handleInputChange = event => {
      const newValue = event?.target?.value;
      setInputValue(newValue);
      if (!newValue) {
        setSelectedOption(null);
      }
    };

    const handleKeyDown = event => {
      switch (event.key) {
        case 'Enter':
          onChange(inputValue);
          setOpenMenu(false);
          event.preventDefault();
          break;
        case 'Escape':
          onChange(inputValue);
          setOpenMenu(false);
          event.preventDefault();
          break;
        case 'Tab': {
          const newValue = optionsList?.[0]?.label
            ?.toLowerCase()
            ?.includes(inputValue.toLowerCase())
            ? optionsList?.[0]?.label
            : '';

          setInputValue(newValue || inputValue);
          newValue && setSelectedOption(newValue);
          setOpenMenu(false);
          break;
        }
        default:
          return '';
      }
    };

    const handleOnChange = item => {
      setSelectedOption(item);
      setInputValue(item);
    };

    const handleBlur = e => {
      onChange(inputValue);
      onBlur?.(e);
      setOpenMenu(false);
    };

    return (
      <Form.Group className="form-input-group">
        {label && (
          <Form.Label
            className={`d-flex align-item-start w-100 form-select-group__label ${labelClassName}`}
          >
            {label}
          </Form.Label>
        )}
        <Dropdown
          className="type-ahead-dropdown-menu"
          onSelect={handleOnChange}
          show={openMenu}
        >
          <Form.Control
            type="text"
            placeholder={placeholder}
            isInvalid={Boolean(error)}
            onKeyDown={handleKeyDown}
            onChange={handleInputChange}
            onBlur={handleBlur}
            ref={ref}
            className={`form-input-group__input ${inputClassName}`}
            autoComplete="off"
            value={inputValue}
            {...rest}
          />
          <Dropdown.Menu className="type-ahead-dropdown-menu">
            {optionsList?.map(item => (
              <Dropdown.Item key={item?.value} eventKey={item.label}>
                {item.label}
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        {error && (
          <Form.Control.Feedback type="invalid">
            <span className="error-icon">
              <ErrorIcon />
            </span>
            {error}
          </Form.Control.Feedback>
        )}
      </Form.Group>
    );
  }
);

TypeAheadInput.displayName = 'TypeAheadInput';

TypeAheadInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    })
  ).isRequired,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  defaultValue: PropTypes.string,
  onBlur: PropTypes.string,
};

TypeAheadInput.defaultProps = {
  error: '',
  labelClassName: '',
  inputClassName: '',
  placeholder: '',
  label: '',
  defaultValue: '',
  onBlur: () => null,
};

export default TypeAheadInput;
